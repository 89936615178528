import DialogueEntryModel from "@/models/DialogueEntryModel";
import {IEntryParent} from "@/helpers/Interfaces";

export default class EntryParentFunctionality
{
    iEntryParent: IEntryParent;
    isBlock: boolean;

    entry: DialogueEntryModel | undefined;

    constructor(entryParent: IEntryParent, entry: DialogueEntryModel | undefined)
    {
        this.iEntryParent = entryParent;
        this.isBlock = entry === undefined;
        this.entry = entry;
    }

    getEntry(index: number)
    {
        return this.iEntryParent.dialogueEntries[index];
    }

    getIndentationLevel()
    {
        return this.isBlock ? 0 : this.entry!.indentationLevel + 1;
    }

    getIndex(entry: DialogueEntryModel)
    {
        return this.iEntryParent.dialogueEntries.indexOf(entry);
    }

    getPreviousEntry(entry: DialogueEntryModel)
    {
        const index = this.getIndex(entry);
        return index <= 0 ? undefined : this.iEntryParent.dialogueEntries[index - 1];
    }

    getLength()
    {
        return this.iEntryParent.dialogueEntries.length;
    }

    addEntry(entry: DialogueEntryModel)
    {
        this.iEntryParent.dialogueEntries.push(entry);
    }

    addEntryAt(entry: DialogueEntryModel, index: number)
    {
        this.iEntryParent.dialogueEntries.splice(index, 0, entry);
    }

    removeEntry(entry: DialogueEntryModel)
    {
        const index = this.iEntryParent.dialogueEntries.indexOf(entry);
        if (index > -1)
        {
            this.iEntryParent.dialogueEntries.splice(index, 1);
        }
    }
}