import UnityBase from "@/unity_export/UnityBase";
import {IDescribedId} from "@/helpers/Interfaces";

export default abstract class UnityDescribableBase<T extends IDescribedId> extends UnityBase<T>
{
    name: string = "";
    description: string = "";

    fromDialogist(input: T)
    {
        super.fromDialogist(input);
        this.name = input.name;
        this.description = input.description;
    }
}