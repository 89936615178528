import {IUndoable, IUndoableText} from "@/helpers/Interfaces";

export default class ChangeTextAction implements IUndoable
{
    name = "Change Text";
    entry: IUndoableText;

    oldText: string;
    newText: string;

    constructor(entry: IUndoableText,  oldText: string)
    {
        this.entry = entry;
        this.oldText = oldText;
        this.newText = entry.undoableText;
    }

    undo()
    {
        this.entry.changeText(this.oldText);
    }

    redo()
    {
        this.entry.changeText(this.newText);
    }
}