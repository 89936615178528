import UnityProject from "@/unity_export/UnityProject";
import {deserialize, serialize} from "class-transformer";
import ProjectModel from "@/models/ProjectModel";
import ImportInfo from "@/functionality/ImportInfo";
import axios from "axios";

export default class ToJson
{
    static currentImportInfo: ImportInfo;

    static async importUsingWebrequest()
    {
        const url = "https://kristinawaldt.com/dialogist/data/babyPhp.php&pass=LOVE";
        const result = await axios.get(url);
        alert(result);
    }

    static importUsingDialog(file: Blob, project: ProjectModel)
    {
        const reader = new FileReader();

        reader.onload = (e) =>
        {
            const result = reader.result;
            const stringResult = String(result);

            this.currentImportInfo = new ImportInfo();
            const unityProject: UnityProject = deserialize(UnityProject, stringResult);
            project.loadFromUnity(unityProject);
            this.currentImportInfo.connectJumps();

            if (project.currentConversation === undefined && project.conversations.length > 0)
            {
                project.currentConversation = project.conversations[0];
            }

            // TODO: Select current conversation etc.
        };

        reader.readAsText(file);
    }

    static load(file: Blob, project: ProjectModel)
    {
        const reader = new FileReader();

        reader.onload = (e) =>
        {
            const result = reader.result;
            const stringResult = String(result);

            project = deserialize(ProjectModel, stringResult);
            project.updateConnectionsAfterLoad();
        };

        reader.readAsText(file);
    }

    static export(project: ProjectModel)
    {
        const unityProject = new UnityProject();
        unityProject.fromDialogist(project);
        this.saveToFile(unityProject, "export");
    }

    static save(project: ProjectModel)
    {
        this.saveToFile(project, "test")
    }

    static saveToFile(data: any, name: string)
    {
        const serializedData = serialize(data);
        const url = window.URL.createObjectURL(new Blob([serializedData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.json');
        document.body.appendChild(link);
        link.click()
    }
}