import { generateUUIDv4 } from "@bitjourney/uuid-v4";
import {IId} from "@/helpers/Interfaces";

export default abstract class BaseIdModel implements IId
{
    id = "";

    constructor() {
        this.generateId();
    }

    private generateId()
    {
        this.id = generateUUIDv4();
    }

    fromUnity(input: IId)
    {
        this.id = input.id;
    }
}