




























import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {IDatabaseActionableEntry} from "@/helpers/Interfaces";
import ListButton from "@/components/ListButton.vue";
import PopupDelete from "@/views/PopupDelete.vue";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
// import store from "@/store/index";

@Component({
    components: {PopupDelete, ListButton}
})

export default class DatabaseActionableEntry extends Vue
{
    @Prop()
    describable!: IDatabaseActionableEntry;

    @Emit()
    customClick()
    {
        return undefined;
    }

    constructor()
    {
        super();
        if (this.describable.hasCreationFocus)
        {
            this.focusInput();
            this.describable.hasCreationFocus = false;
        }
    }

    focusInput()
    {
        this.$nextTick(() =>
        {
            const inputField = this.$refs.inputField as HTMLElement;
            inputField.focus();
        });
    }

    onPaste()
    {
        this.$nextTick(() =>
        {
            this.removeLineBreaks();
        });
    }

    removeLineBreaks()
    {
        this.describable.name = this.describable.name.replace(/(\r\n|\n|\r)/gm, "");
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
