import DialogueEntryModel from "@/models/DialogueEntryModel";
import UnityBase from "@/unity_export/UnityBase";
import UnityBlock from "@/unity_export/UnityBlock";
import {Exclude} from "class-transformer";

export default class UnityEntry extends UnityBase<DialogueEntryModel>
{
    @Exclude()
    parentBlock: UnityBlock | undefined;

    speakerId: string = "";
    talk: string = "";
    // @Exclude()
    // children: UnityEntry[] = [];
    childrenIndex: number[] = [];
    indentationLevel = 0;

    doesJump: boolean = false;
    jumpBack = true;
    jumpBlockId: string = "";

    fromDialogist(input: DialogueEntryModel)
    {
        super.fromDialogist(input);

        // TODO: Reuseable function for speakerId and jumpBlockId

        this.speakerId = input.character === undefined ? "" : input.character.id;
        this.talk = input.talk;
        this.doesJump = input.doesJump;
        this.jumpBack = input.jumpBack;
        this.jumpBlockId = input.jumpBlock === undefined ? "" : input.jumpBlock.id;
        this.indentationLevel = input.indentationLevel;

        for (const value of input.dialogueEntries)
        {
            const newEntry = new UnityEntry();
            newEntry.parentBlock = this.parentBlock;
            this.childrenIndex.push(this.parentBlock!.entries.length);
            this.parentBlock?.entries.push(newEntry);
            newEntry.fromDialogist(value);
        }
    }
}
