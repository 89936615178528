




import {Component, Vue} from "vue-property-decorator";
// import store from "@/store/index";

@Component({
    components: {}
})

export default class SingleBackgroundImage extends Vue
{
    get lightTheme()
    {
        return !this.$vuetify.theme.dark;
    }
}
