import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectView from "@/pages/ProjectView.vue";
import LoginView from "@/pages/LoginView.vue";
import firebase from 'firebase/app';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        alias: '/login',
    },
    {
        path: '/',
        name: 'project',
        component: ProjectView,
        meta: {
            authRequired: true,
        }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.authRequired)) {
//         if (firebase.auth().currentUser) {
//             next();
//         } else {
//             alert('You must be logged in to see this page');
//             next({
//                 path: '/login',
//             });
//         }
//     } else {
//         next();
//     }
// });

// const router = new VueRouter({
// });

export default router
