import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        options: {customProperties: true},
        themes: {
            dark: {
                primary: colors.teal.darken2, // caret, colour accents by Vuetify, ...
                secondary: colors.grey.darken4, // TODO: Use for background?
                accent: '#000000', // TODO: Use for background?
                error: '#8F152A', // as an accent
                info: '#1c2d2e', // backdrop
                success: colors.lightGreen.darken3,
                warning: colors.yellow.darken4,
                anchor: colors.teal.lighten2,
                fabBorder: '#363636',
            },
            light: {
                primary: colors.teal.base,
                secondary: colors.grey.lighten1,
                accent: '#000000',
                error: '#BF1D38',
                info: '#8eaeaf',
                success: colors.lightGreen.darken3,
                warning: colors.yellow.darken4,
                anchor: colors.teal.darken2,
                fabBorder: '#ffffff',
            }
        }
    }
});
