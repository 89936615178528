import {IUndoable} from "@/helpers/Interfaces";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import EntryParentFunctionality from "@/models/EntryParentFunctionality";

export default class IndentEntryAction implements IUndoable
{
    name = "Change Entry Indentation";

    entry: DialogueEntryModel;

    newIsIndented: boolean;
    oldIsIndented: boolean;
    oldParent: EntryParentFunctionality;
    newParent: EntryParentFunctionality;

    newParentIsBlock: boolean;

    oldIndex: number;
    newIndex: number;

    moveSiblings: boolean;
    siblings: DialogueEntryModel[] = [];

    constructor(entry: DialogueEntryModel, newParent: EntryParentFunctionality | undefined, newIndex: number, skipRedo: boolean, moveSiblings: boolean, customOldIndex: number | undefined = undefined)
    {
        this.entry = entry;
        this.oldIsIndented = entry.isChild;
        this.oldParent = entry.parentEntry;
        this.oldIndex = customOldIndex != undefined ? customOldIndex : this.oldParent.getIndex(entry);

        if (newParent === undefined)
        {
            newParent = this.entry.parentEntry.getPreviousEntry(this.entry)!.entryParentFunctionality;
        }
        this.newParentIsBlock = newParent.isBlock;
        this.newParent = newParent;
        this.newIsIndented = !this.newParentIsBlock;

        if (newIndex < 0)
        {
            newIndex = newParent.getLength();
        }
        this.newIndex = newIndex;

        this.moveSiblings = moveSiblings;
        if (moveSiblings)
        {
            for (let i = this.oldIndex + 1; i < this.oldParent.getLength(); i++)
            {
                this.siblings.push(this.oldParent.getEntry(i));
            }
        }

        if (skipRedo)
        {
            this.setupIndent(this.entry, newParent, this.newIsIndented);
        }
    }

    undo()
    {
        this.sharedFunctionality(this.oldParent, this.newParent, this.oldIsIndented, this.oldIndex);
    }

    redo()
    {
        this.sharedFunctionality(this.newParent, this.oldParent, this.newIsIndented, this.newIndex);
    }

    sharedFunctionality(newParent: EntryParentFunctionality, oldParent: EntryParentFunctionality, isIndented: boolean, index: number)
    {
        this.applyIndent(this.entry, newParent, oldParent, isIndented, index);

        if (this.moveSiblings)
        {
            for (let i = 0; i < this.siblings.length; i++)
            {
                this.applyIndent(this.siblings[i], newParent, oldParent, isIndented, index + 1 + i);
            }
        }

        this.entry.hasTalkFocus = true;
    }

    applyIndent(entry: DialogueEntryModel, newParent: EntryParentFunctionality, oldParent: EntryParentFunctionality, isIndented: boolean, index: number)
    {
        this.setupIndent(entry, newParent, isIndented);
        newParent.addEntryAt(entry, index);
        oldParent.removeEntry(entry)
    }

    setupIndent(entry: DialogueEntryModel, newParent: EntryParentFunctionality, isIndented: boolean)
    {
        entry.parentEntry = newParent;
        entry.isChild = isIndented;
        entry.indentationLevel = newParent.getIndentationLevel();
        entry.acceptsChildren = entry.canAcceptChildren();
    }
}