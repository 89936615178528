


































import {Component, Prop, Vue} from "vue-property-decorator";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";

@Component({
    components: {}
})

export default class EntryBlockPreview extends Vue
{
    @Prop() entry!: DialogueEntryModel;
    @Prop() show!: boolean;

    private maxBlockEntries = 5;

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    previewBlockEntriesCount(block: ConversationBlockModel)
    {
        if (block.dialogueEntries.length == 0)
            return 0;

        return Math.min(this.maxBlockEntries, block.dialogueEntries.length);
    }
}
