import UnityDescribableBase from "@/unity_export/UnityDescribableBase";
import CharacterModel from "@/models/CharacterModel";

export default class UnitySpeaker extends UnityDescribableBase<CharacterModel>
{
    color: string = "#ffffff";
    isPlayer: boolean = false;

    fromDialogist(input: CharacterModel)
    {
        super.fromDialogist(input);

        this.color = input.color;
        this.isPlayer = input.isPlayer;
    }
}