














































import {Component, Vue} from "vue-property-decorator";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import store from "@/store";
import SidebarBlocks from "@/views/SidebarBlocks.vue";
import draggable from "vuedraggable";
import DebugTypography from "@/views/DebugTypography.vue";
import SidebarDatabase from "@/views/SidebarDatabase.vue";
import SidebarDrawer from "@/components/SidebarDrawer.vue";
import DialogueEntry from "@/components/DialogueEntry.vue";
import ProjectModel from "@/models/ProjectModel";
import CreateNewBackdrop from "@/components/CreateNewBackdrop.vue";
import DraggableEntryList from "@/components/DraggableEntryList.vue";

@Component({
    components: {
        DraggableEntryList,
        CreateNewBackdrop,
        DialogueEntry,
        SidebarBlocks,
        SidebarDrawer,
        SidebarDatabase,
        DebugTypography,
        draggable
    }
})

export default class DialogueView extends Vue
{
    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    addNewEntryToProject()
    {
        this.project.addDialogueEntryToActive();
    }
}
