import BaseIdModel from "@/models/BaseIdModel";
import ConversationModel from "@/models/ConversationModel";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import DatabaseModel from "@/models/DatabaseModel";
import {Type} from "class-transformer";
import Scrolling from "@/helpers/Scrolling";
import {IDatabaseItem, IDescribed} from "@/helpers/Interfaces";
import ProjectManager from "@/helpers/ProjectManager";
import store from "@/store";
import {DatabaseType} from "@/helpers/Enums";
import CustomClassModel from "@/models/CustomClassModel";
import UndoStack from "@/models/UndoStack";
import CharacterModel from "@/models/CharacterModel";
import ChangeSpeakerAction from "@/actions/ChangeSpeakerAction";
import CreateDatabaseItemAction from "@/actions/CreateDatabaseItemAction";
import DeleteDatabaseItemAction from "@/actions/DeleteDatabaseItemAction";
import Switcher from "@/models/modules/Switcher";
import UnityProject from "@/unity_export/UnityProject";
import LoadHelper from "@/functionality/LoadHelper";
import UnitySpeaker from "@/unity_export/UnitySpeaker";

export default class ProjectModel extends BaseIdModel implements IDescribed, IDatabaseItem
{
    name = "";
    description = "";
    databaseType = DatabaseType.Project;

    @Type(() => ConversationModel)
    private _currentConversation: ConversationModel | undefined = undefined;

    currentCustomClass: CustomClassModel | undefined = undefined;

    @Type(() => ConversationModel)
    conversations: ConversationModel[] = [];

    @Type(() => DatabaseModel)
    database: DatabaseModel;

    switcher: Switcher;
    undoStack: UndoStack;

    rightSelectionType = DatabaseType.None;
    leftSelectionType = DatabaseType.None;

    loadedDialogueEntries: DialogueEntryModel[] = [];

    hasCreationFocus = false;
    isActive = false;

    get actualDialogueEntries()
    {
        return this.isLoading ? this.loadedDialogueEntries : this.dialogueEntries;
    }

    set currentConversation(newConversation: ConversationModel | undefined)
    {
        this.setIsActiveForConversation(false);
        this._currentConversation = newConversation;
        this.setIsActiveForConversation(true);
    }

    get currentConversation()
    {
        return this._currentConversation;
    }

    get currentConversationBlock(): ConversationBlockModel | undefined
    {
        if (this.currentConversation == undefined)
            return undefined;

        return this.currentConversation.currentConversationBlock;
    }

    get dialogueEntries(): DialogueEntryModel[] | undefined
    {
        if (this.currentConversationBlock == undefined)
            return undefined;

        return this.currentConversationBlock.dialogueEntries;
    }

    public static get current(): ProjectModel
    {
        return store.state.activeProject;
    }

    constructor()
    {
        super();
        this.database = new DatabaseModel();
        this.undoStack = new UndoStack();
        this.switcher = new Switcher(this);
    }

    setActive()
    {
        return undefined;
    }

    clearActive()
    {
        return undefined;
    }

    setIsActiveForConversation(state: boolean)
    {
        ProjectManager.setIsActive(this.currentConversation, state);
    }

    deleteDatabaseItem(item: IDatabaseItem)
    {
        const list = ProjectManager.correspondingList(item.databaseType);

        if (list == undefined)
            return;

        const action = new DeleteDatabaseItemAction(item, list);
        this.undoStack.add(action);
    }

    addUndoForNewDatabaseItem(newItem: IDatabaseItem, list: IDatabaseItem[], currentActive: IDatabaseItem | undefined)
    {
        const action = new CreateDatabaseItemAction(newItem, list, currentActive);
        this.undoStack.add(action, true);
    }

    // setIsActiveForCustomClass(state: boolean)
    // {
    //     ProjectManager.setIsActive(this.currentCustomClass, state);
    // }

    addDialogueEntryToActive(index = -1)
    {
        if (this.currentConversationBlock == undefined || this.isLoading)
            return;

        this.currentConversationBlock.createEntry(index);
    }

    loadEntriesTimeout: number[] = [];
    isLoading = false;

    loadEntries()
    {
        if (this.currentConversationBlock == undefined)
            return;

        this.isLoading = true;

        this.loadEntriesTimeout.forEach(function (timer)
        {
            clearTimeout(timer)
        });
        this.loadEntriesTimeout = [];

        const batchSize = 12;
        let round = 0;
        const length = this.currentConversationBlock.dialogueEntries.length;
        const neededRounds = Math.ceil( length / batchSize) - 1;
        this.loadedDialogueEntries = [];

        if (length == 0)
        {
            this.endLoading();
            return;
        }

        for (let i = 0; i < this.currentConversationBlock.dialogueEntries.length; i += batchSize)
        {
            this.loadEntriesTimeout.push(setTimeout(() =>
            {
                const myChunk = this.currentConversationBlock!.dialogueEntries.slice(i, i+batchSize);
                myChunk.forEach((chunk) =>
                {
                    this.loadedDialogueEntries.push(chunk);
                });
                if (i / batchSize == neededRounds)
                {
                    this.endLoading();
                }
            }, 0));
            round++;
        }
    }

    endLoading()
    {
        this.isLoading = false;
    }

    scrollDialogueViewToTop()
    {
        Scrolling.toTop(Scrolling.dialogueEntriesContainer);
    }

    addChangeCharacterUndo(entry: DialogueEntryModel, previousCharacter: CharacterModel | undefined)
    {
        const action = new ChangeSpeakerAction(entry, previousCharacter);
        this.undoStack.add(action, true);
    }

    addDeleteItemUndo(item: IDatabaseItem, list: IDatabaseItem[])
    {
        const action = new DeleteDatabaseItemAction(item, list);
        this.undoStack.add(action);
    }

    updateConnectionsAfterLoad()
    {
        this.currentConversation = this.conversations.find(element => element.id == this.currentConversation?.id);
        this.conversations.forEach((conversation) =>
        {

            conversation.currentConversationBlock = conversation.conversationBlocks.find(element => element.id == conversation.currentConversationBlock?.id);

            conversation.conversationBlocks.forEach((block) =>
                {

                    block.dialogueEntries.forEach((entry) =>
                    {
                        const character = entry.character;
                        entry.character = this.database.characters.find(element => element.id == character?.id);
                    })
                }
            )
        });
    }

    loadFromUnity(input: UnityProject)
    {
        const safeImport = false;

        if (safeImport && input.id != this.id)
        {
            alert("You're trying to import a project with a different id. Import stopped.");
            return;
        }
        LoadHelper.loadDescribable(input, this);
        LoadHelper.changeOrCreate(input.speakers, this.database.characters, () => new CharacterModel());
        LoadHelper.changeOrCreate(input.conversations, this.conversations, () => new ConversationModel());
    }
}