









import {Component, Vue} from "vue-property-decorator";
import Navbar from "@/views/Navbar.vue";
import DialogueView from "@/views/DialogueView.vue";

@Component({
    components: {
        Navbar,
        DialogueView
    }
})

export default class ProjectView extends Vue
{
}
