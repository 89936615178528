










































import {Component, Prop, Vue} from "vue-property-decorator";
import CustomClassEntryModel from "@/models/CustomClassEntryModel";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";

@Component({
    components: {}
})

export default class CustomClassEntryCard extends Vue
{
    @Prop()
    entry!: CustomClassEntryModel;

    constructor()
    {
        super();
        if (this.entry.hasCreationFocus)
        {
            this.focusInput();
            this.entry.hasCreationFocus = false;
        }
    }

    focusInput()
    {
        this.$nextTick(() =>
        {
            const inputField = this.$refs.nameField as HTMLElement;
            inputField.focus();
        });
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
