import ProjectModel from "@/models/ProjectModel";
import ChangeTextAction from "@/actions/ChangeTextAction";
import {IUndoableText} from "@/helpers/Interfaces";

export default class BatchedTextUndo
{
    maxInputTime = 1000;
    startText = "";
    entry: IUndoableText | undefined;
    timer = -1;
    shouldRestart = true;

    setStartText(startText: string)
    {
        this.startText = startText;
    }

    start(entry: IUndoableText)
    {
        this.entry = entry;
        this.shouldRestart = false;
    }

    updateEntry(entry: IUndoableText)
    {
        if (entry !== this.entry || this.shouldRestart)
        {
            this.start(entry);
        }

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {this.end()}, this.maxInputTime);
    }

    end()
    {
        this.shouldRestart = true;

        if (this.entry === undefined)
            return;

        const changeTextAction = new ChangeTextAction(this.entry, this.startText);
        ProjectModel.current.undoStack.add(changeTextAction);
        this.startText = this.entry.undoableText;
        this.entry = undefined;
    }
}