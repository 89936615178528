import Vue from 'vue'
import Vuex from 'vuex'
import ProjectModel from "@/models/ProjectModel";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeProject: new ProjectModel()
  },
  mutations: {
    setProject(state, newProject: ProjectModel)
    {
      state.activeProject = newProject;
    },
  },
  actions: {
    setProject(context, newProject: ProjectModel)
    {
      context.commit('setProject', newProject);
    },
  },
  modules: {
  }
})
