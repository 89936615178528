import {IUndoable, IUndoableText} from "@/helpers/Interfaces";
import BatchedTextUndo from "@/helpers/BatchedTextUndo";
import DatabaseModel from "@/models/DatabaseModel";

export default class UndoStack
{
    stack: IUndoable[] = [];
    index = -1;
    maxStackSize = 15;

    batchedTextUndo = new BatchedTextUndo();

    startBatchedTextUndo(entry: IUndoableText)
    {
        this.batchedTextUndo.setStartText(entry.undoableText);
    }

    updateBatchedTextUndo(entry: IUndoableText)
    {
        this.batchedTextUndo.updateEntry(entry);
    }

    endBatchedTextUndo()
    {
        this.batchedTextUndo.end();
    }

    undo()
    {
        if (this.index < 0)
            return;

        this.stack[this.index].undo();
        this.index--;
    }

    redo()
    {
        if (this.index >= this.stack.length - 1)
            return;

        this.index++;
        this.stack[this.index].redo();
    }

    add(action: IUndoable, dontRedo = false)
    {
        if (this.index < this.stack.length - 1)
        {
            this.stack.length = this.index + 1;
        }

        this.index++;
        this.stack.push(action);

        if (this.stack.length > this.maxStackSize)
        {
            this.stack.splice(0, 1);
            this.index--;
        }

        if (dontRedo)
            return;

        action.redo();
    }

    removeLast()
    {
        if (this.stack.length == 0)
            return;

        this.stack.splice(this.stack.length - 1, 1);
        this.index--;
    }

    clear()
    {
        this.index = -1;
        this.stack.length = 0;
    }
}