export default class Random
{
    static GetInt(min: number, max: number)
    {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static GetColor()
    {
        return Math.floor(Math.random()*16777215).toString(16);
    }

    static scrollToTop(id: string)
    {
        const elm = document.getElementById(id);
        if (elm != null)
        {
            elm.scrollTop = 0;
        }
    }
}