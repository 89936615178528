

















import {Component, Prop, Vue} from "vue-property-decorator";
import {ITooltip} from "@/helpers/Interfaces";

@Component({
    components: {}
})

export default class TabButton extends Vue {
    @Prop({default: ""}) icon: string | undefined;
    @Prop() tooltipText: string | undefined;
    @Prop() tooltipReference: ITooltip | undefined;
    @Prop({default: true}) tabIsLeftAligned: boolean | undefined;

    get preferredTooltipText()
    {
        if (this.tooltipReference != undefined)
        {
            return this.tooltipReference.tooltip;
        }

        return this.tooltipText;
    }

    get buttonClass()
    {
        const extraClass = this.tabIsLeftAligned ? "leftBorder" : "rightBorder";
        return `sidebarTabItem ${extraClass}`;
    }
}
