















import {Component, Vue} from "vue-property-decorator";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
import draggable from 'vuedraggable';
import CustomClassEntryCard from "@/components/CustomClassEntryCard.vue";

@Component({
    components: {CustomClassEntryCard, draggable}
})

export default class DatabaseCustomClass extends Vue
{
    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
