




































import {Component, Vue} from "vue-property-decorator";
import SidebarDrawer from "@/components/SidebarDrawer.vue";
import TabGroup from "@/components/TabGroup.vue";
import DatabaseCharacter from "@/views/DatabaseCharacter.vue";
import {DatabaseType} from "@/helpers/Enums";
import TabContentTransition from "@/components/TabContentTransition.vue";
import PopupCreate from "@/views/PopupCreate.vue";
import ProjectManager from "@/helpers/ProjectManager";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
import SidebarIconButton from "@/components/SidebarIconButton.vue";
import DatabaseCustomClass from "@/views/DatabaseCustomClass.vue";

@Component({
    components: {
        DatabaseCustomClass, DatabaseCharacter,
        SidebarIconButton, TabGroup, SidebarDrawer, PopupCreate, TabContentTransition
    }
})

export default class SidebarDatabase extends Vue
{
    private activeTabComponent = this.project.database.tabs[0].component;
    private activeIndex = 0;

    // TODO: Kinda sloppy
    constructor()
    {
        super();
        this.project.rightSelectionType = this.project.database.tabs[0].databaseType;
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    get customClassType()
    {
        return DatabaseType.Class;
    }

    updateActiveTabByIndex(index: number)
    {
        this.activeIndex = index;
        const tab = this.project.database.tabs[index];
        this.project.rightSelectionType = tab.databaseType;
        this.project.currentCustomClass = tab.customClass;
        this.activeTabComponent = tab.component;
    }

    createCustomClass(name: string)
    {
        this.createObject(this.customClassType, name);
        // TODO: Switch to tab
    }

    createFromTab(name: string)
    {
        this.createObject(this.project.rightSelectionType, name);
    }

    createObject(type: DatabaseType, name: string)
    {
        ProjectManager.createFromSelection(type, name);
    }
}
