




































import {Component, Vue} from "vue-property-decorator";
// import store from "@/store/index";

@Component({
    components: {}
})

export default class DebugTypography extends Vue {
}
