import {IDescribedId, IId} from "@/helpers/Interfaces";
import CharacterModel from "@/models/CharacterModel";
import BaseIdModel from "@/models/BaseIdModel";
import ProjectModel from "@/models/ProjectModel";
import ImportInfo from "@/functionality/ImportInfo";

export default class LoadHelper
{
    static getBlockWithId(id: string, project: ProjectModel)
    {
        for (const conversation of project.conversations)
        {
            for (const block of conversation.conversationBlocks)
            {
                if (block.id != id)
                    continue;
                return block;
            }
        }
        return undefined;
    }

    static getCharacterWithId(id: string, project: ProjectModel)
    {
        for (const character of project.database.characters)
        {
            if (character.id != id)
                continue;
            return character;
        }
        return undefined;
    }

    static loadId(from: IId, to: IId)
    {
        to.id = from.id;
    }

    static loadDescribable(from: IDescribedId, to: IDescribedId)
    {
        this.loadId(from, to);
        to.name = from.name;
        to.description = from.description;
    }

    static changeOrCreate<TFrom extends IId, TTo extends BaseIdModel>(fromList: TFrom[], toList: TTo[], create: Function)
    {
        for (const from of fromList)
        {
            let foundEntry = false;
            for (let i = 0; i < toList.length; i++)
            {
                const existing = toList[i];
                if (existing.id === from.id)
                {
                    foundEntry = true;
                    existing.fromUnity(from);
                    break;
                }
            }

            if (!foundEntry)
            {
                const newEntry: TTo = create();
                newEntry.fromUnity(from);
                toList.push(newEntry);
            }
        }
    }
}