
















import { Component, Vue } from "vue-property-decorator";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
import CreateMockupProject from "@/helpers/CreateMockupProject";
import firebase from 'firebase/app';
import Navbar from "@/views/Navbar.vue";
import DialogueView from "@/views/DialogueView.vue";

@Component({
  components: {
      Navbar,
      DialogueView
  }
})

export default class App extends Vue{

    constructor()
    {
        super();
        document.onkeydown = this.onKeyDown;
        this.project = new CreateMockupProject().createProject();

        // TODO: Remove this test later
        // firebase.database().ref().set({content: 'Lovely'});
    }

    onKeyDown(e: KeyboardEvent)
    {
        if (e.key === "z" && (e.ctrlKey || e.metaKey))
        {
            e.preventDefault();
            this.project.undoStack.undo();
        }
        if (e.key === "y" && (e.ctrlKey || e.metaKey))
        {
            e.preventDefault();
            this.project.undoStack.redo();
        }

        if (e.key === "Tab")
        {
            e.preventDefault();
        }
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
