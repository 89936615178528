import {IDatabaseItem, IUndoable} from "@/helpers/Interfaces";
import ProjectManager from "@/helpers/ProjectManager";

export default class DeleteDatabaseItemAction implements IUndoable
{
    name: string;

    item: IDatabaseItem;
    list: IDatabaseItem[];
    index: number;
    wasActive: boolean;

    constructor(item: IDatabaseItem, list: IDatabaseItem[])
    {
        this.item = item;
        this.list = list;
        this.wasActive = item.isActive;
        this.index = list.findIndex(x => x == item);
        this.name = `Delete ${ProjectManager.creationName(item.databaseType)}`;
    }

    undo()
    {
        ProjectManager.addToList(this.item, this.list, this.index);
        if (this.wasActive)
        {
            this.item.setActive();
        }
    }

    redo()
    {
        ProjectManager.removeFromList(this.index, this.list);
        if (this.wasActive)
        {
            if (this.list.length > 0)
            {
                this.list[0].setActive();
            }
            else
            {
                this.item.clearActive();
            }
        }
    }
}