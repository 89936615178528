import {IUndoable} from "@/helpers/Interfaces";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import CharacterModel from "@/models/CharacterModel";

export default class ChangeSpeakerAction implements IUndoable
{
    name = "Change Speaker";
    dialogueEntry: DialogueEntryModel;
    previousCharacter: CharacterModel | undefined;
    newCharacter: CharacterModel | undefined;

    constructor(dialogueEntry: DialogueEntryModel, previousCharacter: CharacterModel | undefined)
    {
        this.dialogueEntry = dialogueEntry;
        this.newCharacter = dialogueEntry.character;
        this.previousCharacter = previousCharacter;
    }

    undo()
    {
        this.dialogueEntry.character = this.previousCharacter;
    }

    redo()
    {
        this.dialogueEntry.character = this.newCharacter;
    }
}