import BaseIdModel from "@/models/BaseIdModel";
import CharacterModel from "@/models/CharacterModel";
import {Exclude, Expose, Type} from "class-transformer";
import {IDatabaseItem, IEntryParent, IUndoableText} from "@/helpers/Interfaces";
import {DatabaseType} from "@/helpers/Enums";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import DialogueEntry from "@/components/DialogueEntry.vue";
import EntryParentFunctionality from "@/models/EntryParentFunctionality";
import ProjectModel from "@/models/ProjectModel";
import IndentEntryAction from "@/actions/IndentEntryAction";
import UnityEntry from "@/unity_export/UnityEntry";
import LoadHelper from "@/functionality/LoadHelper";
import ToJson from "@/functionality/ToJson";
import Settings from "@/helpers/Settings";

export default class DialogueEntryModel extends BaseIdModel implements IDatabaseItem, IUndoableText, IEntryParent
{
    databaseType = DatabaseType.Entry;

    @Type(() => CharacterModel)
    character?: CharacterModel = undefined;

    talk = "";

    doesJump = false;
    jumpBack = true;
    // TODO: Include, but only the id -> right now, it can break the JSON file (if a block references itself)
    @Exclude()
    jumpBlock?: ConversationBlockModel = undefined;

    isChild = false;
    indentationLevel = 0;
    acceptsChildren = true;

    parentBlock: ConversationBlockModel;
    parentEntry: EntryParentFunctionality;
    dialogueEntries: DialogueEntryModel[] = [];

    uiComponent: DialogueEntry | undefined;

    hasCreationFocus = false;
    hasTalkFocus = false;
    isActive = false;

    entryParentFunctionality: EntryParentFunctionality;

    constructor(parentBlock: ConversationBlockModel)
    {
        super();
        this.parentBlock = parentBlock;
        this.parentEntry = parentBlock.entryParentFunctionality;
        this.entryParentFunctionality = new EntryParentFunctionality(this, this);
    }

    setActive()
    {
        // TODO
        return undefined;
    }

    clearActive()
    {
        // TODO
        return undefined;
    }

    get canJumpForwards()
    {
        return this.doesJump && !this.jumpBack && this.jumpBlock !== undefined;
    }

    get displayedBlockName()
    {
        return this.jumpBlock !== undefined ? this.jumpBlock.name : "(none)";
    }

    get displayedCharacterName()
    {
        return this.character?.name;
    }

    get characterColor()
    {
        return this.character !== undefined ? this.character.color : "transparent";
    }

    get undoableText()
    {
        return this.talk;
    }

    changeText(newText: string): void
    {
        this.talk = newText;
    }

    canAcceptChildren()
    {
        return this.indentationLevel < Settings.maxIndentLevel;
    }

    applyIndentFromDrag(newParent: DialogueEntryModel | ConversationBlockModel | undefined, newIndex: number, oldIndex: number)
    {
        if (newParent === undefined)
        {
            newParent = this.parentBlock;
            this.indentationLevel = 0;
        }

        if (this.parentEntry === newParent.entryParentFunctionality)
            return;

        const action = new IndentEntryAction(this, newParent.entryParentFunctionality, newIndex, true, false, oldIndex);
        ProjectModel.current.undoStack.add(action, true);
    }

    indent()
    {
        if (!this.canIndent())
            return;

        this.addIndentUndo(undefined, -1, false);
    }

    unindent()
    {
        if (this.parentEntry.isBlock)
            return;

        const oldParentEntry = this.parentEntry.entry!;
        const newParent = oldParentEntry.parentEntry;
        const newIndex = newParent.getIndex(oldParentEntry) + 1;

        this.addIndentUndo(newParent, newIndex, true);
    }

    canIndent()
    {
        return this.parentEntry.getIndex(this) != 0;
    }

    addIndentUndo(newParent: EntryParentFunctionality | undefined, newIndex: number, moveSiblings: boolean)
    {
        const action = new IndentEntryAction(this, newParent, newIndex, false, moveSiblings);
        ProjectModel.current.undoStack.add(action);
    }

    fromUnity(input: UnityEntry)
    {
        LoadHelper.loadId(input, this);

        this.character = LoadHelper.getCharacterWithId(input.speakerId, ProjectModel.current);
        this.talk = input.talk;

        this.doesJump = input.doesJump;
        this.jumpBack = input.jumpBack;
        if (input.jumpBlockId.trim() !== "")
        {
            ToJson.currentImportInfo.addJumper(this, input.jumpBlockId);
        }

        this.acceptsChildren = this.canAcceptChildren();

        const inputChildren: UnityEntry[] = [];
        for (let i = 0; i < input.childrenIndex.length; i++)
        {
            inputChildren.push(input.parentBlock!.entries[input.childrenIndex[i]]);
        }

        if (this.acceptsChildren)
        {
            LoadHelper.changeOrCreate(inputChildren, this.dialogueEntries, () =>
            {
                const newEntry = new DialogueEntryModel(this.parentBlock);
                newEntry.parentEntry = this.entryParentFunctionality;
                newEntry.indentationLevel = this.indentationLevel + 1;
                newEntry.isChild = true;
                return newEntry;
            });
        }
        else if (inputChildren.length > 0)
        {
            alert("Children won't be imported because the max level is exceeded.")
        }
    }
}