









import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// import store from "@/store/index";

@Component({
    components: {}
})


export default class ListButton extends Vue {
    @Prop({default: ''}) text: string | undefined;
    @Prop({default: false}) disabled: boolean | undefined;

    @Prop({default: ""}) tooltipText!: string;
    @Prop({default: true}) tooltipIsRight: boolean | undefined;

    get shortTooltipText()
    {
        const maxLength = 600;
        const isLonger = this.tooltipText.length > maxLength;
        const addedString = isLonger ? "..." : "";
        return this.tooltipText.substring(0, maxLength) + addedString;
    }

    @Emit()
    customClick()
    {
        return undefined;
    }
}
