import ConversationModel from "@/models/ConversationModel";
import UnityBlock from "@/unity_export/UnityBlock";
import UnityDescribableBase from "@/unity_export/UnityDescribableBase";

export default class UnityConversation extends UnityDescribableBase<ConversationModel>
{
    blocks: UnityBlock[] = [];

    fromDialogist(input: ConversationModel)
    {
        super.fromDialogist(input);

        for (const entry of input.conversationBlocks)
        {
            const newEntry = new UnityBlock();
            newEntry.fromDialogist(entry);
            this.blocks.push(newEntry);
        }
    }
}