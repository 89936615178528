import {IIsActive} from "@/helpers/Interfaces";
import {DatabaseType} from "@/helpers/Enums";
import ProjectModel from "@/models/ProjectModel";
import ConversationModel from "@/models/ConversationModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import CharacterModel from "@/models/CharacterModel";
import CustomClassModel from "@/models/CustomClassModel";
import CustomClassEntryModel from "@/models/CustomClassEntryModel";

export default class ProjectManager
{
    static setIsActive(isActiveObject: IIsActive | undefined, state: boolean)
    {
        if (isActiveObject != undefined)
        {
            isActiveObject.isActive = state;
        }
    }

    static creationName(selectionType: DatabaseType)
    {
        // TODO: Get current selection, read name
        switch (selectionType)
        {
            case DatabaseType.ClassEntry:
                return "Class Entry";
            default:
                return DatabaseType[selectionType];
        }
    }

    static correspondingList<T>(selectionType: DatabaseType, project = ProjectModel.current)
    {
        switch (selectionType)
        {
            case DatabaseType.Conversation:
                return project.conversations;
            case DatabaseType.Block:
                return project.currentConversation!.conversationBlocks;
            case DatabaseType.Character:
                return project.database.characters;
            case DatabaseType.Class:
                return project.database.customClasses;
            case DatabaseType.ClassEntry:
                return project.currentCustomClass?.entries;
            default:
                return undefined;
        }
    }

    static createFromType(selectionType: DatabaseType, project = ProjectModel.current)
    {
        switch (selectionType)
        {
            case DatabaseType.Project:
                return new ProjectModel();
            case DatabaseType.Conversation:
                return new ConversationModel();
            case DatabaseType.Block:
                return new ConversationBlockModel(project.currentConversation!);
                // TODO: Make sure it's not null
            case DatabaseType.Character:
                return new CharacterModel();
            case DatabaseType.Class:
                return new CustomClassModel();
            case DatabaseType.ClassEntry:
                return new CustomClassEntryModel();
            default:
                return undefined;
        }
    }

    static currentActive(selectionType: DatabaseType, project = ProjectModel.current)
    {
        // TODO: Extend with other types
        switch (selectionType)
        {
            case DatabaseType.Conversation:
                return project.currentConversation;
            case DatabaseType.Block:
                return project.currentConversationBlock;
            default:
                return undefined;
        }
    }

    static createFromSelection(selection: DatabaseType, name: string, project = ProjectModel.current)
    {
        const newObject = ProjectManager.createFromType(selection, project);
        if (newObject == undefined)
            return;

        const list = ProjectManager.correspondingList(selection, project);

        newObject.name = name;
        ProjectManager.addToList(newObject, list);

        if (selection == DatabaseType.Class)
        {
            project.database.createTabForCustomClass(newObject as CustomClassModel);
        }

        if (list == undefined)
            return;

        const currentActive = ProjectManager.currentActive(selection, project);
        project.addUndoForNewDatabaseItem(newObject, list, currentActive);
    }

    static removeFromList<T>(index: number, list: T[] | undefined)
    {
        if (list == undefined)
            return;

        list.splice(index, 1);
    }

    static addToList<T>(type: T, list: T[] | undefined, index = -1)
    {
        if (list == undefined)
            return;

        index = index > -1 ? index : list.length;
        list.splice(index, 0, type);
    }
}