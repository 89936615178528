


























import {Component, Prop, Vue} from "vue-property-decorator";
import Popup from "@/components/Popup.vue";
import {IDeletable} from "@/helpers/Interfaces";
import SingleBackgroundImage from "@/components/SingleBackgroundImage.vue";
import {PopupState} from "@/helpers/Enums";
import ProjectManager from "@/helpers/ProjectManager";

@Component({
    components: {SingleBackgroundImage, Popup}
})

export default class PopupDelete extends Vue
{
    @Prop()
    deleteable!: IDeletable;

    get subtitle()
    {
        return ProjectManager.creationName(this.deleteable.databaseType);
    }

    handleCreateBlockClose(popupState: PopupState)
    {
        if (popupState != PopupState.Success)
            return;

        this.deleteable.delete();
    }
}
