export enum PopupState
{
    Cancel,
    Success,
    Clear
}

export enum DatabaseType
{
    None,
    Project,
    Conversation,
    Block,
    Entry,
    Character,
    Class,
    ClassEntry
}