


































import {Component, Prop, Vue} from "vue-property-decorator";
import Popup from "@/components/Popup.vue";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import {PopupState} from "@/helpers/Enums";
import SingleBackgroundImage from "@/components/SingleBackgroundImage.vue";

@Component({
    components: {SingleBackgroundImage, Popup}
})

export default class PopupChooseBlock extends Vue
{
    $refs!: {
        chooserPopup: Popup;
    };

    @Prop()
    entry!: DialogueEntryModel;

    private showDescription = false;

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    chooseBlock(block: ConversationBlockModel)
    {
        this.entry.jumpBlock = block;
        (this.$refs.chooserPopup as Popup & {show: boolean}).show = false;
    }

    handleCreateBlockClose(popupState: PopupState)
    {
        if (popupState == PopupState.Clear)
        {
            this.entry.jumpBlock = undefined;
        }
    }
}
