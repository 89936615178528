



















import {Component, Vue} from "vue-property-decorator";
import firebase from 'firebase/app';

@Component({
    components: {}
})

export default class LoginView extends Vue
{
    email: string = "";
    password: string = "";

    login()
    {
        firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(() => this.$router.push('/'))
        .catch(error => {alert(error.message)})
    }
}
