import {IUndoableText} from "@/helpers/Interfaces";

export class UndoableTextModel implements IUndoableText
{
    undoableText = "";

    changeText(newText: string): void
    {
        this.undoableText = newText;
    }
}