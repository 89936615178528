import {IDatabaseItem, IUndoable} from "@/helpers/Interfaces";
import ProjectManager from "@/helpers/ProjectManager";

export default class SwitchActiveAction implements IUndoable
{
    oldValue: IDatabaseItem | undefined;
    newValue: IDatabaseItem | undefined;

    get name()
    {
        return `Change Active ${this.typeName}`;
    }

    typeName: string;

    constructor(newValue: IDatabaseItem | undefined, oldValue: IDatabaseItem | undefined)
    {
        this.newValue = newValue;
        this.oldValue = oldValue;

        const databaseType = this.newValue != undefined ? this.newValue.databaseType : this.oldValue!.databaseType;
        this.typeName = ProjectManager.creationName(databaseType);
    }

    undo()
    {
        this.setValue(this.oldValue);
    }

    redo()
    {
        this.setValue(this.newValue);
    }

    setValue(value: IDatabaseItem | undefined)
    {
        if (value != undefined)
        {
            value.setActive();
        }
        else
        {
            this.clearIfUndefined();
        }
    }

    clearIfUndefined()
    {
        if (this.newValue != undefined)
        {
            this.newValue.clearActive();
        }
        else if (this.oldValue != undefined)
        {
            this.oldValue.clearActive();
        }
    }
}