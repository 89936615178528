import BaseIdModel from "@/models/BaseIdModel";
import {IDatabaseItem, IDescribed, INamed, ISelectableDescribed, ITooltip} from "@/helpers/Interfaces";
import {DatabaseType} from "@/helpers/Enums";
import CustomClassEntryModel from "@/models/CustomClassEntryModel";

export default class CustomClassModel extends BaseIdModel implements IDatabaseItem, IDescribed, ITooltip
{
    databaseType = DatabaseType.Class;
    name = "";
    description = "";
    entries: CustomClassEntryModel[] = [];

    hasCreationFocus = false;
    isActive = false;

    setActive()
    {
        // TODO
        return undefined;
    }

    clearActive()
    {
        // TODO
        return undefined;
    }

    get tooltip()
    {
        return this.name;
    }

    constructor()
    {
        super();
        const testEntry = new CustomClassEntryModel();
        testEntry.name = "Test";
        this.entries.push(testEntry);
    }

}