









import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import TabButton from "@/components/TabButton.vue";
import TabItem from "@/uiModels/TabItem";

@Component({
    components: {TabButton}
})

export default class TabGroup extends Vue {
    @Prop()
    tabs!: TabItem[];

    @Prop({default: true}) isLeftAligned: boolean | undefined;

    private tabIndex = 0;

    sendChangeEvents()
    {
        this.activeComponentChanged();
        this.activeIndexChanged();
    }

    @Emit()
    activeComponentChanged()
    {
        return this.tabs[this.tabIndex].component;
    }

    @Emit()
    activeIndexChanged()
    {
        return this.tabIndex;
    }
}
