export default class Scrolling
{
    public static readonly dialogueEntriesContainer = "mainList";

    static toTop(id: string)
    {
        const element = document.getElementById(id);
        if (element == null)
            return;

        element.scrollTop = 0;
    }

    static toBottom(id: string)
    {
        const element = document.getElementById(id);
        if (element == null)
            return;

        element.scrollTop = element.scrollHeight;
    }
}