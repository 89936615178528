import BaseIdModel from "@/models/BaseIdModel";
import {IDatabaseItem, IDescribed, IUndoableText} from "@/helpers/Interfaces";
import {DatabaseType} from "@/helpers/Enums";
import {UndoableTextModel} from "@/models/modules/UndoableTextModel";

export default class CustomClassEntryModel extends BaseIdModel implements IDatabaseItem, IDescribed, IUndoableText
{
    databaseType = DatabaseType.ClassEntry;
    name = "";
    undoableDescription = new UndoableTextModel();

    hasCreationFocus = false;
    isActive = false;

    setActive()
    {
        // TODO
        return undefined;
    }

    clearActive()
    {
        // TODO
        return undefined;
    }

    set description(value: string)
    {
        this.undoableDescription.undoableText = value;
    }

    get description()
    {
        return this.undoableDescription.undoableText;
    }

    get undoableText()
    {
        return this.name;
    }

    changeText(newText: string): void
    {
        this.name = newText;
    }
}