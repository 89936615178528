





































import {Component, Vue} from "vue-property-decorator";
import store from "@/store";
import ListButton from "@/components/ListButton.vue";
import TabContentTransition from "@/components/TabContentTransition.vue";
import TabGroup from "@/components/TabGroup.vue";
import SidebarDrawer from "@/components/SidebarDrawer.vue";
import ProjectModel from "@/models/ProjectModel";
import Blocks from "@/views/Blocks.vue";
import Conversations from "@/views/Conversations.vue";
import PopupCreate from "@/views/PopupCreate.vue";
import DatabaseTabItem from "@/uiModels/DatabaseTabItem";
import {DatabaseType} from "@/helpers/Enums";
import ProjectManager from "@/helpers/ProjectManager";

@Component({
    components: {
        Conversations, Blocks,
        PopupCreate, TabGroup, SidebarDrawer, TabContentTransition, ListButton
    }
})

export default class SidebarBlocks extends Vue
{
    private tabs: DatabaseTabItem[] = [{
        databaseType: DatabaseType.Conversation,
        icon: "mdi-penguin",
        component: "conversations",
        tooltip: "Conversations",
        customClass: undefined
    }, {
        databaseType: DatabaseType.Block,
        icon: "mdi-cat",
        component: "blocks",
        tooltip: "Blocks",
        customClass: undefined
    }];

    private activeTabComponent = this.tabs[0].component;
    private activeIndex = 0;

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    // TODO: Kinda sloppy
    constructor()
    {
        super();
        this.project.leftSelectionType = this.tabs[0].databaseType;
    }

    get canCreateNew()
    {
        if (this.project.leftSelectionType == DatabaseType.Block)
            return this.project.currentConversation != undefined;

        return true;
    }

    updateActiveTabByIndex(index: number)
    {
        this.activeIndex = index;
        const tab = this.tabs[index];
        this.activeTabComponent = tab.component;
        this.project.leftSelectionType = tab.databaseType;
    }

    createObject(name: string)
    {
        ProjectManager.createFromSelection(this.project.leftSelectionType, name);
    }
}
