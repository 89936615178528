import BaseIdModel from "@/models/BaseIdModel";
import Random from "@/helpers/Random";
import {IDatabaseItem, IDescribed, IUndoableText} from "@/helpers/Interfaces";
import {DatabaseType} from "@/helpers/Enums";
import {UndoableTextModel} from "@/models/modules/UndoableTextModel";
import UnitySpeaker from "@/unity_export/UnitySpeaker";
import LoadHelper from "@/functionality/LoadHelper";

export default class CharacterModel extends BaseIdModel implements IDescribed, IDatabaseItem, IUndoableText
{
    databaseType = DatabaseType.Character;
    name = "";
    undoableDescription = new UndoableTextModel();
    color = "#808080";
    isPlayer = false;

    hasCreationFocus = false;
    isActive = false;

    setActive()
    {
        // TODO
        return undefined;
    }

    clearActive()
    {
        // TODO
        return undefined;
    }

    set description(value: string)
    {
        this.undoableDescription.undoableText = value;
    }

    get description()
    {
        return this.undoableDescription.undoableText;
    }

    constructor()
    {
        super();

        this.color = `#${Random.GetColor()}`;
    }

    get undoableText()
    {
        return this.name;
    }

    changeText(newText: string): void
    {
        this.name = newText;
    }

    fromUnity(input: UnitySpeaker)
    {
        LoadHelper.loadDescribable(input, this);
        this.color = input.color;
        this.isPlayer = input.isPlayer;
    }
}