















import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import IconButtonModel from "@/uiModels/IconButtonModel";
// import store from "@/store/index";

@Component({
    components: {}
})

export default class CardSideIconButton extends Vue
{
    @Prop({default: false}) disabled: boolean | undefined;
    @Prop() iconData!: IconButtonModel;

    @Emit()
    customClick()
    {
        return undefined;
    }
}
