import BaseIdModel from "@/models/BaseIdModel";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import {Type} from "class-transformer";
import {IDatabaseActionableEntry, IDeletable, IEntryParent, ISelectableDescribed} from "@/helpers/Interfaces";
import ProjectModel from "@/models/ProjectModel";
import {DatabaseType} from "@/helpers/Enums";
import SwitchDatabaseItem from "@/functionality/SwitchDatabaseItem";
import ConversationModel from "@/models/ConversationModel";
import CreateDialogueEntryAction from "@/actions/CreateDialogueEntryAction";
import ProjectManager from "@/helpers/ProjectManager";
import EntryParentFunctionality from "@/models/EntryParentFunctionality";
import UnityBase from "@/unity_export/UnityBase";
import UnityBlock from "@/unity_export/UnityBlock";
import LoadHelper from "@/functionality/LoadHelper";
import DialogueEntry from "@/components/DialogueEntry.vue";
import UnityEntry from "@/unity_export/UnityEntry";

export default class ConversationBlockModel extends BaseIdModel implements IDatabaseActionableEntry, IDeletable, IEntryParent
{
    name = "";
    description = "";
    isActive = false;
    databaseType = DatabaseType.Block;

    @Type(() => DialogueEntryModel)
    dialogueEntries: DialogueEntryModel[] = [];

    hasCreationFocus = false;

    parent: ConversationModel;
    entryParentFunctionality: EntryParentFunctionality;

    constructor(parent: ConversationModel)
    {
        super();
        this.parent = parent;
        this.entryParentFunctionality = new EntryParentFunctionality(this, undefined);
    }

    setActive()
    {
        if (this.isActive || ProjectModel.current.currentConversation == undefined)
            return;

        this.parent.currentConversationBlock = this;
        SwitchDatabaseItem.reloadDialogueEntries();
    }

    clearActive()
    {
        if (ProjectModel.current.currentConversation == undefined)
            return;

        this.parent.currentConversationBlock = undefined;
    }

    delete()
    {
        if (ProjectModel.current.currentConversation == undefined)
            return;

        ProjectModel.current.addDeleteItemUndo(this, this.parent.conversationBlocks);
    }

    get undoableText()
    {
        return this.name;
    }

    changeText(newText: string): void
    {
        this.name = newText;
    }

    createEntry(index = -1)
    {
        if (index == -1)
        {
            index = this.dialogueEntries.length;
        }

        const newEntry = new DialogueEntryModel(this);
        ProjectModel.current.undoStack.add(new CreateDialogueEntryAction(newEntry, index));
        return newEntry;
    }

    fromUnity(input: UnityBlock)
    {
        input.entries.forEach(function (entry) {entry.parentBlock = input;});

        const inputChildren: UnityEntry[] = [];
        for (let i = 0; i < input.childrenIndex.length; i++)
        {
            inputChildren.push(input.entries[input.childrenIndex[i]]);
        }

        LoadHelper.loadDescribable(input, this);
        // TODO: Only direct children
        LoadHelper.changeOrCreate(inputChildren, this.dialogueEntries, () => new DialogueEntryModel(this));
    }
}