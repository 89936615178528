











import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// import store from "@/store/index";

@Component({
    components: {}
})

export default class CreateNewBackdrop extends Vue
{
    @Prop({default: "Here goes nothing—"})
    title!: string;

    @Prop({default: "Oh, no, take your time."})
    subtitle!: string;

    @Prop({default: "Don't let me pressure you or anything."})
    subsubtitle!: string;

    @Emit()
    customClick()
    {
        return undefined;
    }
}
