























import {Component, Prop, Vue} from "vue-property-decorator";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
import draggable from "vuedraggable";
import DialogueEntry from "@/components/DialogueEntry.vue";
import DialogueEntryModel from "@/models/DialogueEntryModel";

@Component({
    name: 'DraggableEntryList',
    components: {DialogueEntry, draggable}
})

export default class DraggableEntryList extends Vue
{
    @Prop()
    entries!: DialogueEntryModel[];

    @Prop()
    entry!: DialogueEntryModel | undefined;

    onSort(e: any)
    {
        const draggedEntry: DialogueEntryModel | undefined = this.entries[e.newIndex];
        if (draggedEntry === undefined)
            return;

        draggedEntry.applyIndentFromDrag(this.entry, e.newIndex, e.oldIndex);
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
