



























import {Component, Prop, Vue} from "vue-property-decorator";
import SidebarIconButton from "@/components/SidebarIconButton.vue";

@Component({
    components: {SidebarIconButton}
})

export default class SidebarDrawer extends Vue {
    @Prop({default: true}) isRight: boolean | undefined;

    private showDrawer = true;

    get sidebarMargin()
    {
        const conditionalClass = this.isRight ? "sidebarRight" : "sidebarLeft";
        return `sidebarDrawer ${conditionalClass}`;
    }
}
