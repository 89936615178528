





















































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import store from "@/store";
import ProjectModel from "@/models/ProjectModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import PopupChooseBlock from "@/views/PopupChooseBlock.vue";
import PopupCreateBlock from "@/views/PopupCreate.vue";
import {PopupState} from "@/helpers/Enums";
import IconButtonModel from "@/uiModels/IconButtonModel";
import CardSideIconButton from "@/components/CardSideIconButton.vue";
import EntryBlockPreview from "@/components/EntryBlockPreview.vue";
import CharacterModel from "@/models/CharacterModel";
import {VTextarea} from "vuetify/lib";

@Component({
    components: {EntryBlockPreview, CardSideIconButton, PopupCreateBlock, PopupChooseBlock}
})

export default class DialogueEntry extends Vue
{
    private iconButtons: IconButtonModel[] = [{
        icon: "clipboard-account",
        tooltip: "Stage Directions"
    }, {icon: "feather", tooltip: "Notes"},
        {icon: "source-branch", tooltip: "Branch"},
        {icon: "arrow-collapse-right", tooltip: "Parent"},
        {icon: "arrow-collapse-left", tooltip: "Unparent"}];
    private showBlockEntries = true;
    private oldCharacter: CharacterModel | undefined;

    @Prop()
    entry!: DialogueEntryModel;

    @Prop()
    index!: number;

    lazyValue = false;

    defaultLeftMargin = 0;
    levelMargin = 24;

    private showMenu = false;
    menuX = 0;
    menuY = 0;
    items = [{title: "Help"}, {title: "Me"}, {title: "Please"}];

    targetForMenu?: any = undefined;
    caretPositionForMenu = 0;

    get indentationWidth(): number
    {
        return this.entry.isChild ? this.levelMargin : 0;
    }

    get indentStyling(): string
    {
        const width = this.indentationWidth + 10;
        const margin = this.indentationWidth;
        const display = this.entry.isChild ? "inline-block" : "none";

        return `width: ${width}px; margin-left: -${margin}px; display: ${display};`;
    }

    get cardStyling(): string
    {
        const margin = this.defaultLeftMargin + this.indentationWidth;
        return `z-index: 2; margin-left: ${margin}px`;
    }

    constructor()
    {
        super();
        this.entry.uiComponent = this;

        if (this.entry.hasCreationFocus)
        {
            this.lazyValue = true;
            this.focusCharacter();
        }
        else if (this.entry.hasTalkFocus)
        {
            this.lazyValue = true;
            this.focusTalk();
        }
        this.entry.hasCreationFocus = false;
        this.entry.hasTalkFocus = false;
    }

    setMenuToMousePos(e: MouseEvent)
    {
        // this.showMenu = false;
        // this.menuX = e.clientX;
        // this.menuY = e.clientY;
        // this.$nextTick(() => {
        //     this.showMenu = true
        // })
    }

    setMenuTo(x: number, y: number)
    {
        this.showMenu = false;
        this.menuX = x;
        this.menuY = y;
        this.$nextTick(() => {
            this.showMenu = true
        })
    }

    handleTab(e: KeyboardEvent)
    {
        if (e.shiftKey)
        {
            this.entry.unindent();
        } else
        {
            this.entry.indent();
        }
    }

    switchToTalk(e: KeyboardEvent)
    {
        e.preventDefault();
        this.focusTalk();
    }

    focusTalk()
    {
        this.$nextTick(() =>
        {
            // Closes the popup
            this.characterField.blur();

            this.talkField.focus();
        });
    }

    get characterField()
    {
        return this.$refs.characterField as HTMLElement;
    }

    get talkField()
    {
        return this.$refs.talkField as HTMLElement;
    }

    characterChanged()
    {
        ProjectModel.current.addChangeCharacterUndo(this.entry, this.oldCharacter);
        this.focusTalk();
    }

    focusCharacter()
    {
        // TODO: close character popup
        this.$nextTick(() =>
        {
            const inputField = this.$refs.characterField as HTMLElement;
            inputField.focus();
        });
    }

    focusNextEntry()
    {
        // TODO: not really elegant
        const nextIndex = this.index + 1;
        if (ProjectModel.current.currentConversationBlock!.dialogueEntries.length <= nextIndex)
        {
            this.createNewEntry(nextIndex);
        } else
        {
            // TODO: Too many lines
            const nextEntry = ProjectModel.current.currentConversationBlock!.dialogueEntries[nextIndex];
            if (nextEntry.uiComponent != undefined)
            {
                const nextComponent = nextEntry.uiComponent as DialogueEntry;
                nextComponent.focusCharacter();
            }
        }
    }

    createNewEntry(index: number)
    {
        ProjectModel.current.addDialogueEntryToActive(index);
    }

    clickedMenuButton(text: string)
    {
        // Adds text at caret
        // TODO: Check if it's a valid variable zone (inside braces)
        // TODO: Replace existing variable

        const lastIndex = this.entry.talk.length;
        const firstPart = this.entry.talk.substring(0, this.caretPositionForMenu);
        const lastPart = this.entry.talk.substring(this.caretPositionForMenu, lastIndex);

        // TODO: Undo
        this.entry.talk = firstPart + text + lastPart;

        // TODO: Maybe + 1 since there should be a brace behind it
        const cursorPos = this.caretPositionForMenu + text.length;
        this.$nextTick(() =>
        {
            this.targetForMenu!.focus();
            this.targetForMenu!.setSelectionRange(cursorPos, cursorPos);
        });
    }

    handleTalkInput(e: KeyboardEvent)
    {
        if (e.key === "Enter" && e.ctrlKey)
        {
            e.preventDefault();
            this.createNewEntry(this.index + 1)
        } else if (e.key === "Enter" && !e.shiftKey)
        {
            e.preventDefault();
            this.focusNextEntry();
        }
        else if (e.key === "{")
        {
            e.preventDefault();
            const target: any = e.target!;
            const selectionStart = target.selectionStart;
            const selectionEnd = target.selectionEnd;
            const lastIndex = this.entry.talk.length;

            let cursorPos = 0;
            if (selectionStart == selectionEnd)
            {
                const firstPart = this.entry.talk.substring(0, selectionStart);
                const lastPart = this.entry.talk.substring(selectionStart, lastIndex);

                const middlePart = "{}";

                // TODO: UNDO (END BATCH, add middle in other part)
                this.entry.talk = firstPart + middlePart + lastPart;

                cursorPos = selectionStart + middlePart.length - 1;
            }
            else
            {
                let middlePart = this.entry.talk.substring(selectionStart, selectionEnd);
                const origMiddleCount = middlePart.length;
                middlePart = middlePart.trimEnd();
                const trimEnd = origMiddleCount - middlePart.length;
                middlePart = middlePart.trimStart();
                const trimStart = origMiddleCount - trimEnd - middlePart.length;

                const firstPart = this.entry.talk.substring(0, selectionStart + trimStart);
                const lastPart = this.entry.talk.substring(selectionEnd - trimEnd, lastIndex);

                // TODO: UNDO (END BATCH, add middle in other part)
                this.entry.talk = firstPart + "{" + middlePart + "}" + lastPart;

                cursorPos = selectionEnd + 2;
            }

            this.$nextTick(() =>
            {
                target.setSelectionRange(cursorPos, cursorPos);
            });
        }

        // TODO: This is the testing environment for autocomplete variables.
        // else if (e.key === "@" || e.key === "a" || e.key === "A")
        // {
        //     // TODO: Remove all but @
        //     // This makes sure that there isn't an extra @ at the end
        //     e.preventDefault();
        //
        //     this.caretPositionForMenu = e.target!.selectionStart;
        //     this.targetForMenu = e.target!;
        //
        //     const bounding = e.target!.getBoundingClientRect();
        //     let y = bounding.y;
        //     y -= 30;
        //     let x = bounding.x;
        //     x += bounding.width;
        //     x += 15;
        //     this.setMenuTo(x, y);
        //
        //     // const selectionStart = e.target!.selectionStart;
        //     // const lastIndex = this.entry.talk.length;
        //     // const firstPart = this.entry.talk.substring(0, selectionStart);
        //     // const lastPart = this.entry.talk.substring(selectionStart, lastIndex);
        //     //
        //     // const middlePart = "@NAME";
        //
        //     // TODO: UNDO (END BATCH, add middle in other part)
        //     // this.entry.talk = firstPart + middlePart + lastPart;
        //     //
        //     // const cursorPos = selectionStart + middlePart.length;
        //     //
        //     // this.$nextTick(() =>
        //     // {
        //     //     e.target!.setSelectionRange(cursorPos, cursorPos);
        //     // });
        // }
    }

    updateOldCharacter()
    {
        this.oldCharacter = this.entry.character;
    }

    toggleJump()
    {
        this.entry.doesJump = !this.entry.doesJump;
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    handleCreateBlockClose(popupState: PopupState, block: ConversationBlockModel)
    {
        if (popupState == PopupState.Success)
        {
            this.entry.jumpBlock = this.project.currentConversation?.conversationBlocks[this.project.currentConversation?.conversationBlocks.length - 1];
        }
    }
}
