import DialogueEntry from "@/components/DialogueEntry.vue";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import LoadHelper from "@/functionality/LoadHelper";
import ProjectModel from "@/models/ProjectModel";

export default class ImportInfo
{
    jumpEntries: DialogueEntryModel[] = [];
    jumpEntryIds: string[] = [];

    addJumper(entry: DialogueEntryModel, id: string)
    {
        this.jumpEntries.push(entry);
        this.jumpEntryIds.push(id)
    }

    connectJumps()
    {
        for (let i = 0; i < this.jumpEntries.length; i++)
        {
            this.jumpEntries[i].jumpBlock = LoadHelper.getBlockWithId(this.jumpEntryIds[i], ProjectModel.current);
        }
    }
}