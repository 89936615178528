

































































import {Component, Vue} from "vue-property-decorator";
import NavbarDialogueView from "@/views/NavbarDialogueView.vue";
import {deserialize, serialize} from "class-transformer";
import ProjectModel from "@/models/ProjectModel";
import ListButton from "@/components/ListButton.vue";
import store from "@/store";
import DialogueEntryModel from "@/models/DialogueEntryModel";
import UnityEntry from "@/unity_export/UnityEntry";
import UnityBlock from "@/unity_export/UnityBlock";
import UnityProject from "@/unity_export/UnityProject";
import ToJson from '@/functionality/ToJson';
import firebase from 'firebase/app';


@Component({
    components: {NavbarDialogueView, ListButton}
})

export default class Navbar extends Vue {
    private darkTheme = this.$vuetify.theme.dark;
    private brightnessIcons = ["mdi-brightness-3", "mdi-brightness-7"];
    private currentIcon = this.brightnessIcons[this.darkTheme ? 0 : 1];

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }

    get userName()
    {
        const user = firebase.auth().currentUser;
        if (user == null)
            return "(none)";
        return user.email;
    }

    toggleTheme()
    {
        this.darkTheme = !this.darkTheme;
        this.currentIcon = this.darkTheme ? this.brightnessIcons[0] : this.brightnessIcons[1];
        this.$vuetify.theme.dark = this.darkTheme;
    }

    importFromJson(file: Blob)
    {
        ToJson.importUsingDialog(file, this.project);
    }

    exportToJson()
    {
       ToJson.export(this.project);
    }

    saveToJson()
    {
        ToJson.save(this.project);
    }

    loadFromWeb()
    {
        ToJson.importUsingWebrequest();
    }

    loadFromJson(file: Blob)
    {
        ToJson.load(file, this.project);
    }

    signOut()
    {
        alert("Sign out")
        firebase.auth().signOut();
    }
}
