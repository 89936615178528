
























































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {PopupState} from "@/helpers/Enums";

@Component({
    components: {}
})

export default class Popup extends Vue
{
    public show = false;

    @Watch('show')
    onShowChanged()
    {
        if (!this.show)
            return;

        this.$nextTick(() =>
        {
            this.opened();
        });
    }

    @Prop({default: false}) persistent!: boolean;
    @Prop({default: "Submit"}) submitText!: boolean;
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) subtitle!: string;
    @Prop({default: false}) showClearButton!: boolean;
    @Prop({default: true}) showSubmitButton!: boolean;

    get clearState()
    {
        return PopupState.Clear;
    }

    handleClickOutside()
    {
        if (this.persistent)
            return;

        this.endDisplay();
    }

    @Emit()
    opened()
    {
        return undefined;
    }

    @Emit()
    success()
    {
        return undefined;
    }

    successClicked()
    {
        this.success();
        this.endDisplay(PopupState.Success)
    }

    @Emit()
    endDisplay(popupState: PopupState = PopupState.Cancel)
    {
        this.show = false;
        return popupState;
    }
}
