

























import {Component, Vue} from "vue-property-decorator";
import draggable from 'vuedraggable';
import store from "@/store";
import ProjectModel from "@/models/ProjectModel";
import CardSideIconButton from "@/components/CardSideIconButton.vue";
import CharacterCard from "@/components/CharacterCard.vue";

@Component({
    components: {CharacterCard, draggable, CardSideIconButton}
})

export default class DatabaseCharacter extends Vue
{
    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
