import { render, staticRenderFns } from "./SidebarDrawer.vue?vue&type=template&id=283a4d2f&scoped=true&"
import script from "./SidebarDrawer.vue?vue&type=script&lang=ts&"
export * from "./SidebarDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarDrawer.vue?vue&type=style&index=0&id=283a4d2f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283a4d2f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VExpandXTransition,VNavigationDrawer})
