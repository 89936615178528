import UnityEntry from "@/unity_export/UnityEntry";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import UnityDescribableBase from "@/unity_export/UnityDescribableBase";
import {Exclude} from "class-transformer";
import DialogueEntry from "@/components/DialogueEntry.vue";

export default class UnityBlock extends UnityDescribableBase<ConversationBlockModel>
{
    entries: UnityEntry[] = [];
    childrenIndex: number[] = [];

    // @Exclude()
    // children: UnityEntry[] = [];

    fromDialogist(input: ConversationBlockModel)
    {
        super.fromDialogist(input);
        for (const value of input.dialogueEntries)
        {
            const newEntry = new UnityEntry();
            newEntry.parentBlock = this;
            this.childrenIndex.push(this.entries.length);
            this.entries.push(newEntry);
            newEntry.fromDialogist(value);
        }
    }
}