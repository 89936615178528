





















import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import SidebarIconButton from "@/components/SidebarIconButton.vue";
import Popup from "@/components/Popup.vue";
import {DatabaseType, PopupState} from "@/helpers/Enums";
import ProjectManager from "@/helpers/ProjectManager";

@Component({
    components: {Popup, SidebarIconButton}
})

export default class PopupCreateBlock extends Vue
{
    @Prop() selection!: DatabaseType;

    private newName = "";

    focusInput()
    {
        this.$nextTick(() =>
        {
            const inputField = this.$refs.inputField as HTMLElement;
            inputField.focus();
        });
    }

    get subtitle()
    {
        return ProjectManager.creationName(this.selection);
    }

    @Emit()
    success()
    {
        return this.newName;
    }

    @Emit()
    endDisplay(popupState: PopupState)
    {
        return popupState;
    }

    handleCreateBlockClose(popupState: PopupState)
    {
        this.endDisplay(popupState);
        this.resetInput();
    }

    resetInput()
    {
        this.newName = "";
    }
}
