






























































import {Component, Prop, Vue} from "vue-property-decorator";
import CharacterModel from "@/models/CharacterModel";
import IconButtonModel from "@/uiModels/IconButtonModel";
import CardSideIconButton from "@/components/CardSideIconButton.vue";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";

@Component({
    components: {CardSideIconButton}
})

export default class CharacterCard extends Vue
{
    @Prop()
    character!: CharacterModel;

    // TODO: Expandable Description
    private isDescriptionOpen = true;

    // TODO: Tags
    private testTags = ["This", "Is", "A", "Todo", "With Many", "Parts So", "That It", "Wraps"];
    private iconButtons: IconButtonModel[] = [{icon: "brush", tooltip: "Styling"}, {
        icon: "tag",
        tooltip: "Tags"
    }, {icon: "graph", tooltip: "Usage"}];

    constructor()
    {
        super();
        if (this.character.hasCreationFocus)
        {
            this.focusInput();
            this.character.hasCreationFocus = false;
        }
    }

    focusInput()
    {
        this.$nextTick(() =>
        {
            const inputField = this.$refs.nameField as HTMLElement;
            inputField.focus();
        });
    }

    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
