import {IId} from "@/helpers/Interfaces";

export default abstract class UnityBase<T extends IId>
{
    id = "";

    fromDialogist(input: T)
    {
        this.id = input.id;
    }
}