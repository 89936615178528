import ProjectModel from "@/models/ProjectModel";
import UnityConversation from "@/unity_export/UnityConversation";
import UnityDescribableBase from "@/unity_export/UnityDescribableBase";
import UnitySpeaker from "@/unity_export/UnitySpeaker";

export default class UnityProject extends UnityDescribableBase<ProjectModel>
{
    conversations: UnityConversation[] = [];

    speakers: UnitySpeaker[] = [];

    fromDialogist(input: ProjectModel)
    {
        super.fromDialogist(input);

        for (const entry of input.conversations)
        {
            const newEntry = new UnityConversation();
            newEntry.fromDialogist(entry);
            this.conversations.push(newEntry);
        }

        for (const entry of input.database.characters)
        {
            const newEntry = new UnitySpeaker();
            newEntry.fromDialogist(entry);
            this.speakers.push(newEntry);
        }
    }
}