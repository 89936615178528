import { render, staticRenderFns } from "./DialogueView.vue?vue&type=template&id=8af26176&scoped=true&"
import script from "./DialogueView.vue?vue&type=script&lang=ts&"
export * from "./DialogueView.vue?vue&type=script&lang=ts&"
import style0 from "./DialogueView.vue?vue&type=style&index=0&id=8af26176&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8af26176",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VIcon,VList})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
