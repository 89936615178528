












import {Component, Emit, Prop, Vue} from "vue-property-decorator";
// import store from "@/store/index";

@Component({
    components: {}
})

export default class SidebarIconButton extends Vue
{
    @Prop({default: false}) disabled: boolean | undefined;
}
