import BaseIdModel from "@/models/BaseIdModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import {Type} from "class-transformer";
import {
    IDeletable,
    IDatabaseActionableEntry
} from "@/helpers/Interfaces";
import ProjectModel from "@/models/ProjectModel";
import ProjectManager from "@/helpers/ProjectManager";
import {DatabaseType} from "@/helpers/Enums";
import SwitchDatabaseItem from "@/functionality/SwitchDatabaseItem";
import UnityConversation from "@/unity_export/UnityConversation";
import LoadHelper from "@/functionality/LoadHelper";

export default class ConversationModel extends BaseIdModel implements IDeletable, IDatabaseActionableEntry
{
    name = "";
    description = "";
    isActive = false;
    databaseType = DatabaseType.Conversation;

    @Type(() => ConversationBlockModel)
    private _currentConversationBlock: ConversationBlockModel | undefined = undefined;

    @Type(() => ConversationBlockModel)
    conversationBlocks: ConversationBlockModel[] = [];

    hasCreationFocus = false;

    setActive()
    {
        if (this.isActive)
            return;

        ProjectModel.current.currentConversation = this;
        SwitchDatabaseItem.reloadDialogueEntries();
    }

    clearActive()
    {
        ProjectModel.current.currentConversation = undefined;
    }

    set currentConversationBlock(newBlock: ConversationBlockModel | undefined)
    {
        this.setIsActiveForBlock(false);
        this._currentConversationBlock = newBlock;
        this.setIsActiveForBlock(true);
    }

    get currentConversationBlock()
    {
        return this._currentConversationBlock;
    }

    setIsActiveForBlock(state: boolean)
    {
        ProjectManager.setIsActive(this.currentConversationBlock, state);
    }

    delete()
    {
        ProjectModel.current.addDeleteItemUndo(this, ProjectModel.current.conversations);
    }

    get undoableText()
    {
        return this.name;
    }

    changeText(newText: string): void
    {
        this.name = newText;
    }

    fromUnity(input: UnityConversation)
    {
        LoadHelper.loadDescribable(input, this);
        LoadHelper.changeOrCreate(input.blocks, this.conversationBlocks, () => new ConversationBlockModel(this));

        if (this.currentConversationBlock === undefined && this.conversationBlocks.length > 0)
        {
            this.currentConversationBlock = this.conversationBlocks[0];
        }
    }
}