import {IDatabaseItem, IUndoable} from "@/helpers/Interfaces";
import ProjectManager from "@/helpers/ProjectManager";
import {DatabaseType} from "@/helpers/Enums";

export default class CreateDatabaseItemAction implements IUndoable
{
    name: string;
    item: IDatabaseItem;
    list: IDatabaseItem[];
    index: number;

    previousSelection: IDatabaseItem | undefined;

    constructor(item: IDatabaseItem, list: IDatabaseItem[], previousSelection: IDatabaseItem | undefined = undefined)
    {
        this.item = item;
        this.list = list;
        this.index = list.findIndex(x => x == item);
        this.name = `Create ${ProjectManager.creationName(item.databaseType)}`;
        this.previousSelection = previousSelection;

        this.item.setActive();
        item.hasCreationFocus = true;
    }

    undo()
    {
        ProjectManager.removeFromList(this.index, this.list);
        this.previousSelection?.setActive();
    }

    redo()
    {
        ProjectManager.addToList(this.item, this.list, this.index);
        this.item.setActive();
        this.item.hasCreationFocus = true;
    }
}