import {IUndoable} from "@/helpers/Interfaces";
import ProjectModel from "@/models/ProjectModel";
import DialogueEntryModel from "@/models/DialogueEntryModel";

export default class CreateDialogueEntryAction implements IUndoable
{
    name = "Add Dialogue Entry";

    entry: DialogueEntryModel;
    list: DialogueEntryModel[];
    index: number;

    constructor(newEntry: DialogueEntryModel, index: number | undefined = undefined)
    {
        this.entry = newEntry;
        this.list = ProjectModel.current.dialogueEntries!;
        this.index = index != undefined ? index : this.list.length;
        newEntry.hasCreationFocus = true;
    }

    undo()
    {
        this.list.splice(this.index, 1);
    }

    redo()
    {
        this.entry.hasCreationFocus = true;
        this.list.splice(this.index, 0, this.entry);
    }
}
