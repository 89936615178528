import CharacterModel from "@/models/CharacterModel";
import CustomClassModel from "@/models/CustomClassModel";
import DatabaseTabItem from "@/uiModels/DatabaseTabItem";
import {DatabaseType} from "@/helpers/Enums";

export default class Database
{
    characters: CharacterModel[] = [];
    customClasses: CustomClassModel[] = [];
    tabs: DatabaseTabItem[] = [];

    constructor()
    {
        const characterTab: DatabaseTabItem = {
            databaseType: DatabaseType.Character,
            icon: "mdi-account-group",
            component: "database-character",
            tooltip: "Characters",
            customClass: undefined
        };
        this.tabs.push(characterTab);
    }

    createTabForCustomClass(newClass: CustomClassModel)
    {
        const newTab: DatabaseTabItem = {
            databaseType: DatabaseType.ClassEntry,
            icon: "mdi-folder",
            component: "database-custom-class",
            tooltipReference: newClass,
            customClass: newClass
        };
        this.tabs.push(newTab);
    }
}
