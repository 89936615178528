













import {Component, Vue} from "vue-property-decorator";
import ProjectModel from "@/models/ProjectModel";
import store from "@/store";
import ListButton from "@/components/ListButton.vue";
import Conversation from "@/models/ConversationModel";
import DatabaseActionableEntry from "@/components/DatabaseActionableEntry.vue";

@Component({
    components: {DatabaseActionableEntry, ListButton}
})

export default class Conversations  extends Vue
{
    get project(): ProjectModel
    {
        return store.state.activeProject;
    }

    set project(newProject)
    {
        store.dispatch('setProject', newProject);
    }
}
