import "reflect-metadata";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
require('firebase/database');
require('firebase/auth');

const firebaseConfig = {
  apiKey: "AIzaSyBQ1uDZj3wFSEGqtHISZBmjPinm5YYhyr4",
  authDomain: "kw-dialogist.firebaseapp.com",
  databaseURL: "https://kw-dialogist-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "kw-dialogist",
  storageBucket: "kw-dialogist.appspot.com",
  messagingSenderId: "198093690954",
  appId: "1:198093690954:web:5e6ad3f90f06db0b896eb3",
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});
//.$mount('#app');

firebase.auth().onAuthStateChanged(user => {
  store.commit('authStateChanged', user);

  const isAuthed = Boolean(user);
  // @ts-ignore
  const { meta } = router.history.current;

  // if (isAuthed && meta.isPublicOnly) { // e.g. /login + /signup
  //   alert("to project");
  //   router.push('/');
  // } else if (!isAuthed && meta.isPrivate) {
  //   alert("no you login")
  //   router.push('/login');
  // }

  if (meta.authRequired && !isAuthed)
  {
    router.push('/login')
  }
  else if (!meta.authRequired && isAuthed)
  {
    router.push('/')
  }

  app.$mount('#app');
});
