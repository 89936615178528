import ProjectModel from "@/models/ProjectModel";
import ConversationBlockModel from "@/models/ConversationBlockModel";
import SwitchActiveAction from "@/actions/SwitchActiveAction";
import ConversationModel from "@/models/ConversationModel";

export default class Switcher
{
    project: ProjectModel;

    constructor(project: ProjectModel)
    {
        this.project = project;
    }

    switchActiveBlock(block: ConversationBlockModel)
    {
        if (this.project.currentConversation == undefined || block == this.project.currentConversationBlock)
            return;

        const action = new SwitchActiveAction(block, this.project.currentConversationBlock);
        this.project.undoStack.add(action);
    }

    switchActiveConversation(conversation: ConversationModel | undefined)
    {
        if (conversation == this.project.currentConversation)
            return;

        const action = new SwitchActiveAction(conversation, this.project.currentConversation);
        this.project.undoStack.add(action);
    }
}